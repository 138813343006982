export default (state = { total: 0, data: [], lastCheck: null }, action) => {

    let { total, data= [], lastCheck } = state;

    switch(action.type) {
        case 'RT_NOTIFICATION_ADD': {
            total++;
            data.unshift(action.payload)
            lastCheck = action.lastCheck
            break;
        }

        case 'RT_NOTIFICATION_UPDATE': {
            data = action.payload
            lastCheck = action.lastCheck
            break;
        }

        case 'RT_NOTIFICATION_REMOVED': {
            if(total > 0 )
                total--;
            data.shift()
            lastCheck = action.lastCheck
            break;
        }

        case 'ALL_UPDATES_RECEIVED':{
            data = action.payload;
            total = data.length
            break;
        }

        default:
            break;
    }

    return { total, data, lastCheck }
}
import Pages from '../layouts/Pages/Pages.jsx';
import Dashboard from '../layouts/Dashboard/Dashboard.jsx';
import Login from '../views/Auth/LoginPageNew';

const indexRoutes = [
  { path: '/login', name: 'Login', component: Login },
  { path: '/pages', name: 'Pages', component: Pages },
  //{ path: "/devis/:id", name: "Devis", component: DevisPrint },
  { path: '/', name: 'Home', component: Dashboard }
];

export default indexRoutes;

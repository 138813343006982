import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../components/Spinner/Spinner';

const DashboardReport = Loadable({
  loader: () => import('../views/Reports/ReportDashboard'),
  loading: () => <Spinner />
});

const Notes = Loadable({
  loader: () => import('../views/Stats/Notes'),
  loading: () => <Spinner />
});

const Events = Loadable({
  loader: () => import('../views/Stats/Events'),
  loading: () => <Spinner />
});

const Finances = Loadable({
  loader: () => import('../views/Stats/Finances'),
  loading: () => <Spinner />
});

const FinancesReport = Loadable({
  loader: () => import('../views/Reports/ReportFinances'),
  loading: () => <Spinner />
});

const statsRoutes = [
  {
    path: '/dashboard/report',
    name: 'Dashboard Report',
    component: DashboardReport
  },
  {
    path: '/finances/report',
    name: 'Finances Report',
    mini: 'Finances Report',
    component: FinancesReport
  },
  {
    path: '/notes',
    name: 'Notes',
    mini: 'Notes',
    component: Notes
  },
  {
    path: '/events',
    name: 'Events',
    mini: 'Events',
    component: Events
  },
  {
    path: '/finances',
    name: 'Finances',
    mini: 'Finances',
    component: Finances
  }
];

export default statsRoutes;

import React from 'react';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Button
} from 'reactstrap';
import { FormInputs } from '../../components';
import { auth } from 'firebase';
import Auth from '../../layouts/Auth/Auth';

const PasswordRecovery = translate('translations-fr')(
  class extends React.Component {
    state = {
      mode: 'resetPassword',
      oobCode: '',
      continueUrl: '',
      changePassword: {
        new: '',
        confirm: ''
      }
    };

    componentDidMount() {
      this.tryAction();
    }

    tryAction() {
      let urlparams = new URLSearchParams(
        this.props.location.search.replace('?', '')
      );

      if (urlparams.get('mode') === 'resetPassword') {
        auth()
          .verifyPasswordResetCode(urlparams.get('oobCode'))
          .then(email => {
            this.setState({
              mode: urlparams.get('mode'),
              oobCode: urlparams.get('oobCode'),
              email,
              continueUrl: urlparams.get('continueUrl')
            });
          });
      }
    }

    changePassword() {
      let changePassword = this.state.changePassword,
        { oobCode } = this.state,
        { t } = this.props;

      if (changePassword.new !== changePassword.confirm) {
        this.setState({
          err: t('Password do not match')
        });

        return;
      }

      // Save the new password.
      auth()
        .confirmPasswordReset(oobCode, changePassword.confirm)
        .then(() => {
          let { history } = this.props;
          history.push('/login');
        })
        .catch(error => {
          this.setState({
            err: error.message
          });
        });
    }

    getResetPasswordComponent() {
      let { changePassword, email } = this.state,
        { t } = this.props;

      return (
        <div className="col-lg-8 ml-auto mr-auto" style={{ paddingTop: 200 }}>
          <Card>
            <CardHeader>
              <Trans>Change your password</Trans>
              {` ${email}`}
              <hr />
            </CardHeader>
            <CardBody>
              {this.state.err}

              <FormInputs
                ncols={[
                  'col-sm-12 col-md-8 mr-auto ml-auto',
                  'col-sm-12 col-md-8 mr-auto ml-auto'
                ]}
                proprieties={[
                  {
                    label: t('New password'),
                    inputProps: {
                      type: 'password',
                      value: changePassword.new,
                      onChange: event =>
                        this.setState({
                          changePassword: {
                            ...changePassword,
                            new: event.target.value
                          }
                        })
                    }
                  },
                  {
                    label: t('Confirm password'),
                    inputProps: {
                      type: 'password',
                      value: changePassword.confirm,
                      onChange: event =>
                        this.setState({
                          changePassword: {
                            ...changePassword,
                            confirm: event.target.value
                          }
                        })
                    }
                  }
                ]}
              />
            </CardBody>
            <CardFooter className="justify-content-end clearfix">
              <Button
                color="info"
                className="float-right"
                onClick={() => this.changePassword()}
              >
                <Trans>Change password</Trans>
              </Button>
            </CardFooter>
          </Card>
        </div>
      );
    }

    getComponent() {
      let { mode } = this.state;

      switch (mode) {
        case 'resetPassword':
          return this.getResetPasswordComponent();
        default:
          return null;
      }
    }

    render() {
      return (
        <Auth>
          <div className="login-page">
            <Container>
              <Col xs={12} md={8} lg={8} className="ml-auto mr-auto">
                {this.getComponent()}
              </Col>
            </Container>
          </div>
        </Auth>
      );
    }
  }
);

export default PasswordRecovery;

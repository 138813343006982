import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import security from './security';
import updates from './updates';

const layout = (state = {}, action) => {
  return state;
};

const lang = (state = { current: 'fr', availables: [] }, action) => {
  return state;
};

/**
 * Notifications
 * @param state
 * @param action
 * @return {*[]}
 */
const notifications = (state = [], action) => {
  switch (action.type) {
    case 'NOTIFICATION_ADD': {
      state.push(action.payload);
      break;
    }

    case 'NOTIFICATION_REMOVE': {
      state.pop();
      break;
    }

    case 'CLEAN_NOTIFICATIONS':
      state = [];
      break;

    default:
      break;
  }

  return [...state];
};

const user = (state = {}, action) => state;

export default combineReducers({
  layout,
  security,
  lang,
  notifications,
  updates,
  user,
  fb: firebaseReducer
});

import Loadable from 'react-loadable';
import React from 'react';
import Spinner from '../components/Spinner/Spinner';

const Section = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'catalog-section' */ '../views/Material/LeroyMerlin/Sections'),
  loading: () => <Spinner />,
  render: (loaded, cprops) => {
    let Component = loaded.default;
    return <Component location={cprops.location} match={cprops.match} />;
  }
});

const SubSection = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'catalog-section' */ '../views/Material/LeroyMerlin/SubSections'),
  loading: () => <Spinner />,
  render: (loaded, cprops) => {
    let Component = loaded.default;
    return <Component location={cprops.location} match={cprops.match} />;
  }
});

const userRoutes = [
  {
    path: '/',
    name: 'Leroy Merlin',
    mini: 'LM',
    icon: 'shopping_basket',
    render: props => <Section {...props} />
  },
  {
    path: '/:type/',
    name: 'Leroy Merlin',
    mini: 'LM',
    icon: 'shopping_basket',
    render: props => <Section {...props} />
  },
  {
    path: '/:type/:section/',
    name: 'Leroy Merlin',
    mini: 'LM',
    icon: 'shopping_basket',
    render: props => <SubSection {...props} />
  }
];

export default userRoutes;

import React from 'react';
import Loadable from 'react-loadable';
import { Spinner } from '../components';

const pagesRoutes = [
  {
    path: '/',
    name: 'Projects',
    short: 'Projects',
    mini: 'PR',
    icon: 'tech_mobile',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-home' */ '../views/Projects/Home'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/new',
    name: 'New Project',
    short: 'New',
    mini: 'LP',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-form' */ '../views/Projects/Form'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/by-status/:status',
    name: 'Projects',
    short: 'New',
    mini: 'LP',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-home' */ '../views/Projects/Home'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id',
    name: 'Project Details',
    short: 'Login',
    mini: 'LP',
    icon: 'users_circle-08',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-details' */ '../views/Projects/Details/Details'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/edit',
    name: 'Project Edition',
    short: 'Edit',
    mini: 'LP',
    icon: 'users_circle-08',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-form' */ '../views/Projects/Form'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/orders',
    name: 'Orders',
    short: 'Orders',
    mini: 'OR',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-devis' */ '../views/Projects/Order/Orders'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/accounting',
    name: 'Accounting',
    short: 'Accounting',
    mini: 'A',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-devis' */ '../views/Projects/Accounting/Accounting'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/devis',
    name: 'Devis',
    short: 'Devis',
    mini: 'DP',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-devis' */ '../views/Projects/Devis/Edit/DevisEdit'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/devis/:devisId',
    name: 'Devis',
    short: 'Devis',
    mini: 'DP',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-devis' */ '../views/Projects/Devis/Edit/DevisEdit'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/devis/:devisId/details',
    name: 'Devis Details',
    short: 'Devis Details',
    mini: 'DD',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-devis' */ '../views/Projects/Devis/Details/DevisDetails'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/compound/devis',
    name: 'Devis Compound Page',
    short: 'Devis',
    mini: 'DC',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-devis' */ '../views/Projects/Devis/Details/DevisDetails'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/compound/devis/print',
    name: 'Print Devis',
    short: 'Print',
    mini: 'PP',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-devis-print' */ '../views/Projects/Devis/DevisCompoundPrint'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/:id/devis/:devisId/print',
    name: 'Print Devis',
    short: 'Print',
    mini: 'PP',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: 'projects-devis-print' */ '../views/Projects/Devis/DevisPrint'
        ),
      loading: () => <Spinner />
    })
  },
  {
    path: '/reports/notes',
    name: 'Report Projects Notes',
    short: 'RPN',
    mini: 'RPN',
    icon: 'business_chart-bar-32',
    component: Loadable({
      loader: () => import('../views/Reports/ReportNotes'),
      loading: () => <Spinner />
    })
  },
  {
    path: '/reports/events',
    name: 'Report Projects Events',
    short: 'RPE',
    mini: 'RPE',
    icon: 'business_chart-bar-32',
    component: Loadable({
      loader: () => import('../views/Reports/ReportEvents'),
      loading: () => <Spinner />
    })
  },
  {
    redirect: true,
    path: '/projects',
    pathTo: '/',
    name: 'Projects'
  },
  {
    path: '/:id/plan/:planId',
    name: 'Plan Details',
    short: 'PD',
    mini: 'PD',
    icon: 'business_money-coins',
    component: Loadable({
      loader: () => import('../views/Projects/Plans/PlanDetails'),
      loading: () => <Spinner />
    })
  }
];

export default pagesRoutes;
